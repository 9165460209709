import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CoreMedia Tag`}</h1>
    <p>{`CoreMedia Tag allows you to react to your customers and extract analytics in the `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/engagement-cloud/"
      }}>{`Engagement Cloud`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      